import Button from 'components/Button'
import AppLayout from 'layouts/AppLayout'
import { Fields } from '@redsales/ds'
import useRegister from './useRegister'
import { _validators } from '@redsales/ds/utils'
import './Register.scss'
import { navigate } from 'gatsby'
import makeTrackings from 'utils/make/trackings'
import { registerFormId } from 'utils/make/constants'

const Register = ({ location }) => {
  const { state } = location || {}

  const { product, isEditing, onBackFromEdit, document, variant } = state || {}

  const { control, formState, onSubmit, setError } = useRegister({
    product,
    isEditing,
    onBackFromEdit,
    document,
  })

  const getPageTitleAndDescription = () => {
    if (isEditing) {
      return {
        title: 'Editar dados pessoais',
        description: '',
      }
    }

    if (variant === 'B') {
      return {
        title: 'Só mais um pouco',
        description: 'Complete os campos abaixo com seus dados pessoais.',
      }
    }

    return {
      title: 'Vamos começar?',
      description: 'Preencha os campos abaixo com seus dados pessoais.',
    }
  }

  return (
    <AppLayout
      contentHeaderInfos={{
        ...getPageTitleAndDescription(),
        onClick: () => {
          if (isEditing) {
            navigate(onBackFromEdit)
            return
          }

          navigate(-1)
        },
        onTrack: () => {
          makeTrackings.formBacked({
            formId: registerFormId,
            formName: product,
            stepId: 'dbe87283-39e4-46f0-bdb9-12a4777c2e24',
            stepName: 'basic-data',
            stepNumber: 1,
          })
        },
      }}
      contentClassName="register-wrapper"
    >
      <form onSubmit={onSubmit} className="register-wrapper__form">
        <div className="form-container">
          <Fields.Input.Text
            control={control}
            name="document"
            label="CPF"
            mask="999.999.999-99"
            autoComplete="on"
            maskChar=""
            type="text"
            inputmode="numeric"
            pattern="[0-9.-]*"
            onBlur={(e) => {
              if (!_validators.cpf(e.target.value) || !e.target.value) {
                setError('document', {
                  type: 'manual',
                  message: 'CPF inválido',
                })
              }
            }}
          />

          <Fields.Input.Text
            control={control}
            name="name"
            label="nome completo"
            onBlur={(e) => {
              if (!_validators.name(e.target.value) || !e.target.value) {
                setError('name', {
                  type: 'manual',
                  message: 'Nome inválido',
                })
              }
            }}
          />

          <Fields.Input.Text
            control={control}
            name="email"
            label="email"
            type="email"
            onBlur={(e) => {
              if (!_validators.email(e.target.value) || !e.target.value) {
                setError('email', {
                  type: 'manual',
                  message: 'Email inválido',
                })
              }
            }}
          />

          <Fields.Input.Text
            control={control}
            name="phone"
            label="celular"
            mask="(99) 99999-9999"
            maskChar=""
            autoComplete="mobile tel-national"
            type="tel"
            onBlur={(e) => {
              if (!_validators.phone(e.target.value) || !e.target.value) {
                setError('phone', {
                  type: 'manual',
                  message: 'Celular inválido',
                })
              }
            }}
          />
        </div>

        <p className="register-wrapper__juridic-text">
          Ao continuar, autorizo o uso dos meus dados de navegação e dados
          pessoais aqui informados para validação cadastral. Estou ciente que
          poderei ser contatado por email, sms e whatsapp com comunicações sobre
          a experiência do tour Red Ventures Brasil. Também autorizo o envio dos
          dados para meu próprio email, de forma a demonstrar os processos
          internos de tecnologia da Red Ventures Brasil. Finalmente, estou de
          acordo que meus dados sejam usados durante a experiência do tour e
          deletados ao termino do processo.
        </p>

        <Button
          className="register-wrapper__button"
          type="submit"
          disabled={
            !formState?.isValid ||
            formState?.isSubmitting ||
            Object.keys(formState?.errors || {}).length > 0
          }
        >
          {isEditing ? 'Salvar' : 'Próximo'}
        </Button>
      </form>
    </AppLayout>
  )
}

export default Register
